const config = {};

module.exports = {
    init: baseUrl => {
        config.baseUrl = baseUrl;
        return config.baseUrl && true;
    },
    getCallbacks: async (appId, brand, eventType) => {
        let callbackUrl = new URL(`${config.baseUrl}/1/admin/callbacks`);
        if(appId){ callbackUrl.searchParams("app_id", appId); }
        if(brand){ callbackUrl.searchParams("brand", brand); }
        if(eventType){ callbackUrl.searchParams("event_type", eventType); }
        
        return fetch(callbackUrl, {
            method:'GET'
        })
        .then(resp => {
            let callbacks = resp.json();
            
            return callbacks;
        }).catch(error => {
            return error;
        });
    },
    saveCallback: async (saveParams) => {
        let callbackUrl = new URL(`${config.baseUrl}/1/admin/callbacks`);

        return fetch(callbackUrl, {
            method: 'PUT',
            headers: {
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(saveParams)
        }).then(resp => {
            return resp;
        }).catch(error => {
            return error;
        });

    },
    deleteCallback: async (appId, eventType) => {
        let callbackUrl = new URL(`${config.baseUrl}/1/admin/callbacks/event/${encodeURIComponent(eventType)}/appId/${encodeURIComponent(appId)}`);

        return fetch(callbackUrl, {
            method: 'DELETE',
            headers: {
               'Content-Type': 'application/json'
            }
        }).then(resp => {
            return resp;
        }).catch(error => {
            return error;
        });
    }
}