const axios = require("axios");

const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }
        if (context.readOnlyURL) {
            config.readOnlyURL = context.readOnlyURL;
            console.log(`config.baseURL: ${config.readOnlyURL}`);
        } else {
            console.log('could not find readOnlyURL in context object');
        }

        return config.baseURL && true;
    },

    getAuditData: async payload => {
        let url = `${config.baseURL}/admin/audit`

        return await axios.post(url, payload, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getTemplates: async () => {
        return await axios.post(`${config.readOnlyURL}/admin/template`, {
            action: "get"
        }).then(response => {
            if (response.hasOwnProperty("message")) {
                throw response.message;
            }
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    saveTemplate: async (brand, profileType, source, destination, publish, tag, encodedTemplate) => {
        return await axios.post(`${config.baseURL}/internal/template`, {
            action: "set",
            brand,
            profile_type: profileType,
            source,
            destination,
            publish,
            tag,
            template: encodedTemplate
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deleteTemplate: async (brand, profileType, source, destination, tag) => {
        return await axios.post(`${config.baseURL}/internal/template`, {
            action: "delete",
            brand,
            profile_type: profileType,
            source,
            destination,
            tag
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    testTemplate: async (brand, profileType, source, destination, publish, encodedTemplate, encodedTestPayload) => {
        return await axios.post(`${config.readOnlyURL}/admin/template`, {
            action: "test",
            brand,
            profile_type: profileType,
            source,
            destination,
            publish,
            template: encodedTemplate,
            test_payload: encodedTestPayload
        }, {
            validateStatus: status => status >= 200 && status < 500
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    getMetadata: async () => {
        return await axios.post(`${config.baseURL}/admin/metadata`, {
            "action": "get"
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setMetadata: async (key, value) => {
        return await axios.post(`${config.baseURL}/internal/metadata`, {
            "action": "set",
            "key": key,
            "value": value
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    reprocess: async (start, end, airingIds, brand, profileType, mediaIds, showIds, source, status, titleIds, videoIds) => {
        return await axios.post(`${config.baseURL}/internal/reprocess`, {
            ...brand && brand !== "ALL" && {brand: brand},
            ...titleIds && titleIds.length > 0 && {
                title_id: {
                    matches_any: true,
                    values: titleIds
                }
            },
            ...airingIds && airingIds.length > 0 && {
                airing_id: {
                    matches_any: true,
                    values: airingIds
                }
            },
            ...showIds && showIds.length > 0 && {
                show_id: {
                    matches_any: true,
                    values: showIds
                }
            },
            ...mediaIds && mediaIds.length > 0 && {
                media_id: {
                    matches_any: true,
                    values: mediaIds
                }
            },
            ...status && status !== "ALL" && {status: status},
            ...profileType && profileType !== "ALL" && {profile_type: profileType},
            ...source && source !== "ALL" && {source: source},
            ...start && {start: Math.floor(start / 1000)},
            ...end && {end: Math.floor(end / 1000)},
            ...videoIds && videoIds.length > 0 && {
                video_ids: {
                    matches_any: true,
                    values: videoIds
                }
            }
        }).then(response => {
                return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getRules: async () => {
        return await axios.post(`${config.baseURL}/admin/rule`, {
            action: "GET"
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    saveRule: async (role, enabled, description, search, replace, priority) => {
        return await axios.post(`${config.baseURL}/admin/rule`, {
            action: "SET",
            role,
            search,
            replace,
            description,
            enabled,
            priority: parseInt(priority)
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deleteRule: async (enabled, search, replace) => {
        return await axios.post(`${config.baseURL}/admin/rule`, {
            action: "DELETE",
            search,
            replace,
            enabled
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getGroups: async () => {
        return await axios.get(`${config.baseURL}/groups/`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    updateGroup: async (brand, originalGroup, updatedGroup) => {
        return await axios.post(`${config.baseURL}/admin/groups`, {
            action: "UPDATE",
            brand,
            group: originalGroup,
            value: updatedGroup
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    addGroup: async (brand, group) => {
        return await axios.post(`${config.baseURL}/admin/groups`, {
            action: "CREATE",
            brand,
            group
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deleteGroup: async (brand, group) => {
        return await axios.post(`${config.baseURL}/admin/groups`, {
            action: "DELETE",
            brand,
            group
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
