const axios = require("axios");
const config = {};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;
        return config.baseURL && true;
    },

    getAssetsForBrand: async brand => {
        return await axios.get(`${config.baseURL}/api/1/assets`,{
            params: {
                brand
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getAssetsForBrandAndMediaId: async (brand, mediaId) => {
        return await axios.get(`${config.baseURL}/api/1/assets`, {
            params: {
                brand,
                mediaId
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    }
};
