const axios = require("axios");

let config = {};

module.exports = {
    init: (baseURL) => {
        config.baseURL = baseURL;

        return config.baseURL && true;
    },

    shouldBlackout: async (feed, titleId, platform, zipcode, state, country, lat, lon, ipAddress) => {
        let params = {};
        if (feed) {
            params.feed = feed;
        }
        if (titleId) {
            params.titleId = titleId;
        }
        if (platform) {
            params.platform = platform;
        }
        if (zipcode) {
            params.zipcode = zipcode;
        }
        if (state) {
            params.state = state;
        }
        if (country) {
            params.country = country;
        }
        if (lat) {
            params.lat = lat;
        }
        if (lon) {
            params.lon = lon;
        }
        if (ipAddress) {
            params.ipAddress = ipAddress;
        }

        return await axios.get(`${config.baseURL}/shouldBlackout`, {
            params,
            withCredentials: false
        }).then(response => {
            console.log("(BlackoutMasterDetailView.shouldBlackout): params: ", params, " response: ", response.data);
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
