const config = {};
const axios = require('axios');
axios.defaults.withCredentials = true;

let checkIfInitialized = () => {
    if (!config || !config.baseURL) {
        throw Error('ErrorReport was not initialized with a baseURL string.');
    }
    return true;
};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getAll: async () => {
        checkIfInitialized();
        return axios.get(`${config.baseURL}/all`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    get: async (id, hour, start, end, app, msg) => {
        checkIfInitialized();
        msg = msg.split('<').join('');
        let queries = `?id=${id}&hour=${hour}&start=${start}&end=${end}&app=${app}&msg=${msg}`;

        return axios.get(`${config.baseURL}/get${queries}`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }

};
