const axios = require("axios");
const jwt = require("jsonwebtoken");
const config = {
    permissions: [],
    userPermissions: []
};

const init = async context => {
    if (context.baseURL) {
        console.log(`${context.baseURL} is the base URL`);
        config.baseURL = context.baseURL;
    } else {
        console.log("(AuthDataProvider init) Could not find baseURL in context object.");
    }
    config.userPermissions = [];
    return config.baseURL && true;
};

const isInitialized = () => config.baseURL && true;
const isLoggedIn = () => {
    return config.user && config.user.isLoggedIn;
}

const queryUserData = async (redirectUri=undefined) => {
    return await axios.get(`${config.baseURL}/profile`, {
        withCredentials: true,
        params: {
            redirectUri
        }
    }).then(response => {
        console.log("(AuthDataProvider) queryUserData returning user data from request: ", response.data);
        config.user = response.data;
        return response.data;
    });
};

const getUserData = async () => {
    if (!config.baseURL) throw new Error("Not initialized");

    if (config.user) {
        console.log("(AuthDataProvider) getUserData returning cached user data: ", config.user);
        return config.user;
    } else {
        return await queryUserData();
    }
};

const userData = async () => config.user;

const checkIfAuthorized = async permissionsRequired => {
    if (!isInitialized() || (!Array.isArray(permissionsRequired) && permissionsRequired !== null)) {
        console.log("(AuthDataProvider checkIfAuthorized) permissionsRequired was not null or not an array: ", permissionsRequired);
        return false;
    }

    return await getUserData().then(getUserPermissions).then(async authz => {
        config.userPermissions = authz;
        return userHasRequiredPermissions(authz, permissionsRequired);
    }).catch(error => console.error("(AuthDataProvider checkIfAuthorized) error: ", error));
};


const getUserPermissions = async (json) => {
    if (json.isLoggedIn === true) {
        config.user = json;
        return json.authz || [];
    } else {
        return [];
    }
};

const updateAllPermissions = async () => {
    await getAllPermissions().then(response => {
        console.log("AuthDataProvider.updateAllPermissions: received response ", response);
        if (Array.isArray(response)) {
            config.permissions = response.slice();
        } else {
            config.permissions = [];
        }
    })
}

const userHasRequiredPermission = (authz, permissions, open, service, module, scope=undefined, role=undefined) => {
    if (open === true) {
        return true;
    }

    if (Array.isArray(authz) && authz.includes("urn:all:aspen-power")) {
        return true;
    }

    console.log("AuthDataProvider.userHasRequiredPermission: ", authz, permissions, open, service, module, scope, role);
    for (const permission of permissions) {
        const serviceMatches = permission.service === "all" || permission.service === service;
        const moduleMatches = permission.module === "all" || permission.module === module;
        const scopeMatches = scope === undefined || permission.scope === "all" || permission.scope === scope;
        const roleMatches = role === undefined || permission.role === role;
        const urnMatches = Array.isArray(authz) && authz.includes(permission.urn);

        console.log("AuthDataProvider.userHasRequiredPermission: open?", open, `service ${service} matches? `, serviceMatches,
            `module matches? ${moduleMatches}`, `scope ${scope} matches? `, scopeMatches, `role ${role} matches? `, roleMatches, `urn ${permission.urn} matches? `, urnMatches, "authz?", authz);
        if (serviceMatches && moduleMatches && scopeMatches && roleMatches && urnMatches) {
            return true;
        }
    }
    return false;
};

const userHasRequiredPermissions = (permissionsPossessed, permissionsRequired) => {
    if (permissionsPossessed !== undefined && permissionsPossessed.includes("urn:all:aspen-power")) {
        console.log("(AuthDataProvider) userHasRequiredPermissions: user has urn:all:aspen-power");
        return true;
    }


    if (!Array.isArray(permissionsRequired)) {
        console.log(`(AuthDataProvider) userHasRequiredPermissions: permissionsRequired was an invalid value: ${permissionsRequired}`);
        return false;
    }

    if (!Array.isArray(permissionsPossessed)) {
        permissionsPossessed = [];
    }

    if (permissionsRequired.includes("urn:guest:guest") && permissionsPossessed.length > 0) {
        console.log("(AuthDataProvider) userHasRequiredPermissions: User requested service requiring only guest permissions");
        return true;
    }

    let userHasRequiredPermissions = false;

    permissionsRequired = permissionsRequired.map(createPermissionObject);
    permissionsPossessed = permissionsPossessed.map(createPermissionObject);

    console.log("(AuthDataProvider) userHasRequiredPermissions: permissions required: ", permissionsRequired);
    console.log("(AuthDataProvider) userHasRequiredPermissions: permissions possessed: ", permissionsPossessed);

    for (let permissionRequired of permissionsRequired) {
        userHasRequiredPermissions = permissionsPossessed.filter(permissionPossessed =>
            permissionRequired.type === permissionPossessed.type &&
            permissionRequired.service === permissionPossessed.service &&
            permissionsRequired.module === permissionPossessed.module &&
            (permissionRequired.scope === "*" || (permissionPossessed.scope === permissionRequired.scope)) &&
            permissionsRequired.role === permissionPossessed.role
        ).length > 0;
        console.log("(AuthDataProvider) userHasRequiredPermissions: permissionRequired: ", permissionRequired);


        if (userHasRequiredPermissions) {
            console.log("(AuthDataProvider) userHasRequiredPermissions: user had a required permission: ", permissionRequired);
            break;
        }
    }

    if (!userHasRequiredPermissions) {
        console.log("(AuthDataProvider) userHasRequiredPermissions: user did not have a required permission: ", permissionsRequired);
    }

    return userHasRequiredPermissions;
};

const createPermissionObject = permission => {
    if (!permission || typeof permission != "string") {
        throw new Error("Attempted to create a permission object with an invalid input");
    }

    let permissionArray = permission.split(":");

    if (![3, 5].includes(permissionArray.length)) {
        throw new Error(`Attempted to create a permission object with an invalid string: ${permission}`);
    }

    if (permissionArray.length === 3) {
        return {
            type: permissionArray[0],
            service: permissionArray[1],
            scope: permissionArray[2]
        };
    } else if (permissionArray.length === 5) {
        return {
            type: permissionArray[0],
            service: permissionArray[1],
            module: permissionArray[2],
            scope: permissionArray[3],
            role: permissionArray[4]
        }
    }
};

const putS2SToken = async tokenJSON => {
    return await axios.post(`${config.baseURL}/admin/s2s`, tokenJSON);
};

const getS2STokens = async () => {
    return await axios.get(`${config.baseURL}/admin/s2s`)
        .then(response => {
            console.log("AuthDataProvider.getS2STokens response:", response);
            let tokens = {};
            for (let tokenData of response.data) {
                if (tokenData.jwt) {
                    let token = {
                        id: tokenData._id,
                        description: tokenData.description,
                        name: tokenData.name,
                        title: tokenData.name,
                        ttl: tokenData.ttl.toString(),
                        saved: true
                    };
                    let {header, payload} = jwt.decode(tokenData.jwt, {complete: true});
                    console.log("AuthDataProvider.getS2STokens header and payload:", header, payload);
                    token.ver = header.ver.toString();

                    if (token.ver === "1") {
                        token.version = "1";
                    }

                    token.kid = header.kid;
                    token.endpoint = payload.endpoint;
                    token.payload = payload.payload;
                    token.iss = payload.iss;
                    token.secret = "";
                    tokens[token.id] = token;
                }
            }
            return tokens;
    });
};

const getAttToken = async (body) => {
    let url = `${config.baseURL}/admin/attToken`

    return fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
        .then(payload => {
            return payload
        })
        .catch(error => {
            return {error}
        });
};

const getStrapiiToken = async body => {
    return axios.post(`${config.baseURL}/admin/strapiiToken`, body).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    });
};

const getAllPermissions = async () => {
    return await axios.get(`${config.baseURL}/permissions`, {
        withCredentials: true
    }).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    });
};

module.exports = {
    init: init,
    userData: userData,
    isLoggedIn: isLoggedIn,
    getUserData: getUserData,
    queryUserData: queryUserData,
    isInitialized: isInitialized,
    checkIfAuthorized: checkIfAuthorized,
    updateAllPermissions: updateAllPermissions,
    createPermissionObject: createPermissionObject,
    userHasRequiredPermission: userHasRequiredPermission,
    userHasRequiredPermissions: userHasRequiredPermissions,
    getS2STokens: getS2STokens,
    putS2SToken: putS2SToken,
    getAttToken: getAttToken,
    getStrapiiToken: getStrapiiToken,

    // From UserDataProvider
    getPermissions: async () => {
        return await axios.get(`${config.baseURL}/admin/getAllPermissions`, {
            withCredentials: true
        }).then(response => {
            config.permissions = response.data;
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getUsers: async () => {
        return await axios.get(`${config.baseURL}/admin/listUsers`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getUserPermissions: async (userId) => {
        return await axios.get(`${config.baseURL}/admin/listPermissions/${userId}`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    updateUserPermissions: async (userId, authz) => {
        return await axios.post(`${config.baseURL}/admin/updateUserAuthz/${userId}/${authz.toString()}`, {},
            {
                withCredentials: true
            }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getUserSessions: async userId => {
        return await axios.get(`${config.baseURL}/admin/getSessions/${userId}`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    deleteUserSession: async sessionId => {
        return await axios.get(`${config.baseURL}/admin/deleteSession/${sessionId}`, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getDetailsForPermission: async urn => {
        return await axios.post(`${config.baseURL}/admin/permissions`, {
            action: "get",
            urn
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getAllPermissions: getAllPermissions,

    getAllAdminPermissions: async () => {
        return await axios.post(`${config.baseURL}/admin/permissions`, {
            action: "get_all"
        }).then(response => {
            return response.data;
        });
    },

    createPermission: async (urn, service, module, scope, role, description) => {
        return await axios.post(`${config.baseURL}/admin/permissions`, {
            action: "create",
            permission: {
                urn,
                service,
                module,
                scope,
                role,
                description
            }
        }, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    }
};
