const config = {};
const axios = require("axios");
axios.defaults.withCredentials = true;

module.exports = {
    init: (baseURL, validEnvironments, validBrands) => {
        config.baseURL = baseURL;
        console.log(`config.baseURL: ${config.baseURL}`);

        config.validEnvironments = validEnvironments || [];
        console.log(`config.validEnvironments: ${JSON.stringify(config.validEnvironments)}`);

        config.validBrands = validBrands || [];
        console.log(`config.validBrands: ${JSON.stringify(config.validBrands)}`);

        return config.baseURL && config.validEnvironments && config.validBrands && true;
    },

    submit: async (environment, brand, deviceId) => {
        if (!environment || !config.validEnvironments.includes(environment.toLowerCase())) {
            throw new Error(`Invalid environment ${environment}; the only accepted environments are ${JSON.stringify(config.validEnvironments)}`);
        }

        if (!brand || !config.validBrands.includes(brand)) {
            throw new Error(`Invalid brand ${brand}; the only accepted brands are ${JSON.stringify(config.validBrands)}`);
        }

        if (!deviceId) {
            throw new Error("Please enter a device ID");
        }

        if (environment.toLowerCase() !== "stage" && deviceId.toLowerCase() === "all") {
            throw new Error("The device ID can only be all if the environment is Stage");
        }

        return await axios.get(`${config.baseURL}?env=${environment}&requestor_id=${brand}&device_id=${deviceId}`).then(response => {
            return response;
        }).catch(error => {
            console.log(`${error.toString()}`);
            throw new Error(error);
        })
    }
};
