const config = {};
const axios = require('axios');
axios.defaults.withCredentials = true;

let checkIfInitialized = () => {
    if (!config || !config.baseURL) {
        throw Error('AdConfigurationDataProvider was not initialized with a baseURL string.');
    }
    return true;
};

const init = context => {
    if (context.baseURL) {
        config.baseURL = context.baseURL;
        console.log(`config.baseURL: ${config.baseURL}`);
    } else {
        console.log('could not find baseURL in context object');
    }

    return config.baseURL && true;
};

const get = async profileId => {
    checkIfInitialized();

    return axios.post(`${config.baseURL}/get`, {
        profileId
    }).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    });
};

const getAll = async () => {
    checkIfInitialized();

    return axios.post(`${config.baseURL}/all`, {}).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    })
};

const newProfile = async newProfile => {
    checkIfInitialized();

    let {profile, ...profileWithoutMetadata} = newProfile;
    profileWithoutMetadata.profile = {};
    return axios.post(`${config.baseURL}/new`, {
        profile: newProfile
    }).then(response => {
        console.log("(AdConfigurationDataProvider.new) new response: ", response.data);
        return response.data;
    }).catch(error => {
        return {error};
    });
};

const deactivate = async profileId => {
    checkIfInitialized();

    return axios.post(`${config.baseURL}/deactivate`, {
        profileId
    }).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    });
};

const reactivate = async profileId => {
    checkIfInitialized();

    return axios.post(`${config.baseURL}/reactivate`, {
        profileId
    }).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    });
};

const update = async profile => {
    checkIfInitialized();

    return axios.post(`${config.baseURL}/update`, {
        profile
    }).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    })
};

const clone = async profileId => {
    checkIfInitialized();

    return axios.post(`${config.baseURL}/duplicate`, {
        profileId
    }).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    });
};

const getNewProfileId = async () => {
    checkIfInitialized();

    return axios.post(`${config.baseURL}/getNewId`, {}).then(response => {
        return response.data;
    }).catch(error => {
        return {error};
    });
};

module.exports = {
    init: init,
    get: get,
    getAll: getAll,
    getNewProfileId: getNewProfileId,
    update: update,
    newProfile: newProfile,
    clone: clone,
    deactivate: deactivate,
    reactivate: reactivate
}
