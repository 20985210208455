const config = {};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getCrates: async () => {
        let url = `${config.baseURL}/admin/crates`;
        
        return fetch(url, {
            credentials: 'include',
        })
        .then(payload => {
            return payload;
        })
    },

    getForklift: async () => {
        let url = `${config.baseURL}/admin/forklift`;

        return fetch(url, {
            credentials: 'include',
        })
            .then(payload => {
                return payload;
            })
    },

    saveCrate: async (userId, newCrateData) => {
        let url = `${config.baseURL}/admin/addCrate`;
        
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                cratesData: newCrateData,
                id: userId
            }),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(payload => {
            return payload;
        })
    },

    getTags: async (githubRepo, githubToken) => {
        let url = `https://api.github.com/repos/turnercode/${githubRepo}/tags`;

        return fetch(url, {
            method: 'GET',
            crossdomain: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.github.everest-preview+json',
                'Authorization': githubToken
            }
        })
            .then(payload => {
                return payload;
            })
    },

    deployApp: async (githubRepo, githubToken, deploymentName, env, tag, accessKeyId, secretAccessKey,
                      deploymentAccessKeyId, deploymentSecretAccessKey, appEngineDeploymentKey, target) => {
        let url = `https://api.github.com/repos/turnercode/${githubRepo}/dispatches`;
        let eventType = deploymentName + " " +env+ " "+tag+" Deployment";
        return fetch(url, {
            method: 'POST',
            crossdomain: true,
            body: JSON.stringify({
                event_type: eventType,
                client_payload: {
                    DEPLOYMENT_ACCESS_KEY_ID: deploymentAccessKeyId,
                    DEPLOYMENT_SECRET_ACCESS_KEY: deploymentSecretAccessKey,
                    SECRETS_ACCESS_KEY_ID: accessKeyId,
                    SECRETS_SECRET_ACCESS_KEY: secretAccessKey,
                    APP_ENGINE_DEPLOYMENT_KEY: appEngineDeploymentKey,
                    DEPLOYMENT_TARGET:target,
                    VERSION: tag,
                    ENV: env
                }
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.github.everest-preview+json',
                'Authorization': githubToken
            }
        }).then(payload => {
                return payload;
            })
    },


    deleteCrate: async (userId, crateId) => {
        let url = `${config.baseURL}/admin/deleteCrate/${crateId}`;
        
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify({id: userId}),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(payload => {
            return payload;
        })
    },



};


