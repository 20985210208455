const axios = require("axios");
axios.defaults.withCredentials = true;

const config = {};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;

        return config.baseURL && true;
    },

    setMetadata: (key, value) => {
        return axios.post(`${config.baseURL}/internal/metadata`, {
            action: "SET",
            key,
            value
        }, {
            headers: {
                "app-id": process.env.SYNAPSE_APP_ID
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });

    },

    getMetadata: () => {
        return axios.post(`${config.baseURL}/internal/metadata`, {
            action: "GET"
        }, {
            headers: {
                "app-id": process.env.SYNAPSE_APP_ID
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getEvents: (users=[], events=[], start=null, end=null) => {
        return axios.post(`${config.baseURL}/admin/event`, {
            action: "GET",
            ...start && {start: Date.parse(start) / 1000},
            ...end && {end: Date.parse(end) / 1000},
            ...users.length > 0 && {
                user: {
                    matches_any: true,
                    values: users
                },
            },
            ...events.length > 0 && {
                event: {
                    matches_any: true,
                    values: events
                }
            }
        }, {
            headers: {
                "app-id": process.env.SYNAPSE_APP_ID
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    submitDelete: (events) => {
        return axios.post(`${config.baseURL}/admin/event_update`, {
            action: "INACTIVATE",
            db_ids: events}, {
                headers: {
                    "app-id": process.env.SYNAPSE_APP_ID
                }
            }
        ).then(response => {
            return response.status;
        }).catch(error => {
            return {error};
            }
        )
    },

    submitAddEvent: (user, events) => {
        return axios.post(`${config.baseURL}/event`, {
            user: user,
            set: events
        }, {
            headers: {
                "app-id": process.env.SYNAPSE_APP_ID
            }
        }).then(response => {
            return response.status;
        }).catch(error => {
            return {error};
        })
    },


    submitUpdate: (_id, userId, eventId, points) => {{
        return axios.post( `${config.baseURL}/admin/event_update`, {
            action: "ACTIVATE",
            ..._id,
            ...userId,
            ...eventId,
            ...points}, {
            headers: {
                "app-id": process.env.SYNAPSE_APP_ID
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }},

    submitClearCache: () => {{
        return axios.post( `${config.baseURL}/admin/clear_cache`, {}, {
            headers: {
                "app-id": process.env.SYNAPSE_APP_ID
            }
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }}
}
