const config = {};
const axios = require('axios');
axios.defaults.withCredentials = true;

let checkIfInitialized = () => {
    if (!config || !config.baseURL) {
        throw Error('ARADataProvider was not initialized with a baseURL string.');
    }
    return true;
};

module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.error('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getAll: async () => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/appids`).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        })
    },

    get: async appId => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/findbyid`, {
            appId
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    update: async app => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/edit`, {
            app
        }
        ).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    new: async app => {
        checkIfInitialized();

        return axios.post(`${config.baseURL}/admin/register`, {
            app
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    }
};
