const config = {};
module.exports = {
    init: context => {
        if (context.baseURL) {
            config.baseURL = context.baseURL;
            console.log(`config.baseURL: ${config.baseURL}`);
        } else {
            console.log('could not find baseURL in context object');
        }

        return config.baseURL && true;
    },

    getAllMetadata: async () => {
        let url = `${config.baseURL}/admin/metadata/all`;

        return fetch(url, {
            method:'GET',
            credentials: 'include'

        })
        .then(payload => {
            return payload;
        })
    },

    getAllPublicMetadata: async () => {
        let url = `${config.baseURL}/admin/metadata/appIds`;

        return fetch(url, {
            method:'GET',
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },

    getAllRules: async () => {
        let url = `${config.baseURL}/admin/rules`;

        return fetch(url, {
            method:'GET',
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },

    getConfigMetadata: async () => {
        let url = `${config.baseURL}/admin/config`;

        return fetch(url, {
            method:'GET',
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },
    setConfigMetadata: async (metadata) => {
        let url = `${config.baseURL}/admin/config`

        return fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(metadata)
        })
            .then(payload => {
                return payload
            })
            .catch(error => {
                return {error}
            })
    },

    setRules: async (data) => {
        let url = `${config.baseURL}/admin/rules`;
        return fetch(url, {
            method:'POST',
            body: JSON.stringify(
                data
            ),
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },

    deleteRule: async (data) => {
        let url = `${config.baseURL}/admin/rules/delete`;
        return fetch(url, {
            method:'POST',
            body: JSON.stringify(
                data
            ),
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },

    setMetadata: async (givenAppId, tokenTime, lastResetTime, resetOffset, hashAppId, fields, tokenValue, ttl, secret, updateFrequency, entitledNetworks, repeatInterval, ipCheck) => {
        tokenTime = parseInt(tokenTime);
        ttl = parseInt(ttl);

        if (Number.isNaN(tokenTime) || Number.isNaN(ttl)) {
            throw Error(`Error parsing value in tokenTime or ttl.  TokenTime: ${tokenTime}.  ttl: ${ttl}`);
        }
        let url = `${config.baseURL}/admin/metadata`;
            var data = {
                "lastResetTime": lastResetTime || 0,
                "appId": givenAppId || "",
                "resetOffset": resetOffset || 0,
                "hashAppId": hashAppId,
                "ipCheck":ipCheck,
                "repeatInterval":repeatInterval,
                "entitledNetworks":entitledNetworks,
                "fields": fields,
                "ttl": ttl || 300,
                "secret": secret
            }
            data[tokenValue] = tokenTime;
            if(tokenValue === "sw"){
                data["updateFrequency"] = updateFrequency || 0;
            }

        return fetch(url, {
            method:'POST',
            body: JSON.stringify(
                data
            ),
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },
    getTokenTime: async (data) => {
        let url = `${config.baseURL}/admin/time/get`;
        return fetch(url, {
            method:'POST',
            body: JSON.stringify(
                data
            ),
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },
    setTokenTime: async (data) => {
        let url = `${config.baseURL}/admin/time/set`;
        return fetch(url, {
            method:'POST',
            body: JSON.stringify(
                data
            ),
            credentials: 'include'

        })
            .then(payload => {
                return payload;
            })
    },
}