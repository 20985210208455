const config = {}

module.exports = {
    init: baseUrl => {
        config.baseUrl = baseUrl;
        return config.baseUrl && true;
    },
    getCreditNotes: async (startMillis, endMillis) => {
        let creditNoteUrl = new URL(`${config.baseUrl}/pusher/search`);
        
        let requestData = {};

        if(startMillis) {
            requestData.startMillis = startMillis.toString();
        }
        if(endMillis) {
            requestData.endMillis = endMillis.toString();
        }
        
        return fetch(creditNoteUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestData)

        })
        .then(resp => {
            if(resp.ok) {
                return resp.json();
            } else  {
                console.log("Error found", resp);
                return []
            }
            
        })
        .catch(error => {
            console.log("Error occurred", error);
            return null;
        });
    },
    generateCreditNotes: async(batchSize) => {
        let generatorUrl = new URL(`${config.baseUrl}/pusher/creditnote/generate`);

        return fetch(generatorUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "batchSize": batchSize.toString()
            })
        }).then(resp => {
            return resp;
        }).catch(error => {
            return error;
        });
    }
}