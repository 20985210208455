const axios = require("axios");

const config = {};

module.exports = {
    init: baseURL => {
        config.baseURL = baseURL;
        return config.baseURL && true;
    },

    audit: async criteria => {
        return await axios.post(`${config.baseURL}/admin/audit`, criteria).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getMetadata: async () => {
        return await axios.post(`${config.baseURL}/internal/metadata`, {
            action: "GET"
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    setMetadata: async (key, value) => {
        return await axios.post(`${config.baseURL}/internal/metadata`, {
            action: "SET",
            key,
            value
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    getAssets: async (mediaIds=[], odtAiringIds=[], assetIds=[], brands=null, start=null, end=null) => {
        return await axios.post(`${config.baseURL}/admin/asset`, {
            action: "GET",
            ...mediaIds && mediaIds.length > 0 && {
                mediaId: {
                    matchesAny: true,
                    values: mediaIds
                }
        },
            ...odtAiringIds && odtAiringIds.length > 0 && {
                odtAiringId: {
                    matchesAny: true,
                    values: odtAiringIds
                }
            },
            ...assetIds && assetIds.length > 0 && {
                assetId: {
                    matchesAny: true,
                    values: assetIds
                }
            },
            ...brands && brands.length > 0 && {
                brand: {
                    matchesAny: true,
                    values: brands
                }
            },
            ...start && {start: Math.floor(start / 1000)},
            ...end && {end: Math.floor(end / 1000)}
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },

    updateAsset: async asset => {
        return await axios.post(`${config.baseURL}/admin/asset`, {
            action: "UPDATE",
            payload: {...asset}
        }).then(response => {
            return response.data;
        }).catch(error => {
            return {error};
        });
    },
};
